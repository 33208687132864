import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { MatRadioModule } from '@angular/material/radio';
import { ProgramLog } from '@meplis/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'mec-program-radio',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule],
  templateUrl: './program-radio.component.html',
  styleUrls: ['./program-radio.component.scss'],
})
export class ProgramRadioComponent implements OnChanges {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<ProgramLog>();

  public value: string | string[];

  constructor() {}

  public ngOnChanges(): void {
    if (this.programNode?.log) {
      this.value = this.programNode.log.results[0]?.value as string;
    }
  }

  public selectItem(value: string): void {
    this.value = value;

    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.variable.name, value: value }],
    };

    this.onSelectItemChange.emit(log);
  }
}
