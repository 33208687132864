import { Environment } from './models/environment';

export const environment: Environment = {
  production: false,
  name: 'DEVELOPMENT',
  formCarryUrl: 'https://formcarry.com/s/',
  formCarryApi:
    'Jpsop0D9TBJVm8akHL8LAvWJT2U9fKvgZp5cDO8W5AUNIEEcSOUNgqJCV7E4zmUj',
  social: 'https://dev-social.meplis.com',
  socialV1: 'https://dev.meplis.com/social/v1',
  campusV1: 'https://dev.meplis.com/trc/v1',
  campusV2: 'https://dev.meplis.com/trc/v2',
  campusV3: 'https://dev.meplis.com/trc/v3',
  chainV2: 'https://dev.meplis.com/chain/v2',
  filesV1: 'https://dev.meplis.com/files/v1',
  filesV2: 'https://dev.meplis.com/files/v2',
  filesV3: 'https://dev.meplis.com/files/v3',
  apiUrl: 'https://dev.meplis.com/',
  staticUrl: 'https://static.meplis.com/',
  translateUrl: 'https://i18n.meplis.com/',
  imagesHost: 'https://dev.meplis.com/files/v1',
  authentication: {
    clientToken: '641de8fc9c68a73ead37f9eb',
    supportsTokenAccess: true,
  },
  google: {
    analyticsStorageId: 'ga-campus-development',
    analyticsTrackerId: 'G-3PL1EYST0G',
    mapsKey: 'AIzaSyCARfd30IYGP7PvKdvkRKFcGVyeUPnrk6U',
    recaptchaKey: '6Lc2MIUpAAAAANU2lDbvr_IFUUjTk9ajlimlw3fC',
  },
  oneSignal: {
    appId: '5019a63b-beaa-417c-9617-316c9c922b80',
    debug: false,
  },
};
