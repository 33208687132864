import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { MatSliderModule } from '@angular/material/slider';
import { ProgramAnswer } from '../../../common/models/care-monitor/program-answer';
import { ProgramLog } from '@meplis/common';

@Component({
  selector: 'mec-program-eqvas',
  standalone: true,
  imports: [CommonModule, MatSliderModule],
  templateUrl: './program-eqvas.component.html',
  styleUrls: ['./program-eqvas.component.scss'],
})
export class ProgramEqvasComponent {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<ProgramLog>();

  public stepValue: number = 1;
  public tiers: { value: string; hideRule?: boolean; position?: string }[] = [];
  public widthContainer: string;

  public optionSelectedIndex: number;

  constructor() {}

  public ngOnChanges(): void {
    if (this.programNode) {
      this._handleTiers();

      const value = this.programNode.log.results[0]?.value;
      if (value) {
        this.optionSelectedIndex = parseInt(value.toString()) - 1;
      }
    }
  }

  public selectItem(value: string, index: number): void {
    this.optionSelectedIndex = index;

    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.variable.name, value: value }],
    };

    this.onSelectItemChange.emit(log);
  }

  public formatLabel(value: number): string {
    return `${value}`;
  }

  private _handleTiers(): void {
    this.widthContainer =
      'width:' + 68 * this.programNode.validation.maxValue + 'px';

    for (
      var i = this.programNode.validation.minValue;
      i < this.programNode.validation.maxValue + 1;
      i++
    ) {
      this.tiers.push({
        value: i.toString(),
      });
    }
  }
}
