<div id="footerElement" class="footer">
  <div class="footer__tabs">
    <div class="footer__logo-container">
      <ng-container *ngIf="this.supportEmail">
        <h5 class="footer__support footer__font">
          {{ 'SUPPORT_TITLE' | translate }}
        </h5>

        <button
          id="bt__footer-contact"
          class="footer__contact-btn btn btn--outline"
          (click)="openMailLink()"
        >
          {{ 'CONTACT_US' | translate }}
        </button>
      </ng-container>
    </div>

    <div class="footer__menus-container">
      <div class="footer__vertical-line"></div>
      <div class="footer__menus">
        <h5
          class="footer__menu-label footer__font"
          (click)="goToPage(menu.targetSlug)"
          *ngFor="let menu of menuData.menuItems"
        >
          {{ menu?.content?.title | translate }}
        </h5>
        <!-- Start Skeleton loader -->
        <ng-container *ngIf="isLoadingMenus">
          <h5
            class="footer__menu-label footer__font"
            *ngFor="let item of [0, 1, 2, 3, 4]"
            [medSkeletonLoading]="true"
          >
            Test Test Test
          </h5>
        </ng-container>
        <!-- End Skeleton loader -->
      </div>
    </div>

    <!-- <div class="footer__social-container">
      <div class="footer__vertical-line footer__vertical-line-social"></div>

      <div class="footer__social-buttons">
        <h5> Follow Us</h5>
        <button class="footer__social-btn  btn btn--no-bg btn--icon">
          <fa-icon [icon]="facebookIcon"></fa-icon>
        </button>
        <button class="footer__social-btn  btn btn--no-bg btn--icon">
          <fa-icon [icon]="instagramIcon"></fa-icon>
        </button>
        <button class="footer__social-btn  btn btn--no-bg btn--icon">
          <fa-icon [icon]="linkedinIcon"></fa-icon>
        </button>
      </div>

    </div> -->
  </div>

  <div class="footer__footer-content footer__font">
    <ng-container *ngIf="!isLoadingInstance">
      <div
        class="footer__font"
        [innerHtml]="instance?.content?.footerContent"
      ></div>
    </ng-container>
    <!-- Start Skeleton loader -->
    <p
      class="footer__font"
      *ngIf="isLoadingInstance"
      [medSkeletonLoading]="true"
    ></p>
    <!-- End Skeleton loader -->
    <ng-container *ngIf="!isLoadingInstance">
      <div class="footer__font" [innerHtml]="instance?.content?.support"></div>
    </ng-container>
    <!-- Start Skeleton loader -->
    <p
      class="footer__font"
      *ngIf="isLoadingInstance"
      [medSkeletonLoading]="true"
    ></p>
    <!-- End Skeleton loader -->
  </div>
</div>

<div class="footer-logo">
  <img
    class="logo footer-logo__img"
    [ngClass]="{ hidden: isLoadingLogo || hiddenLogo }"
    (error)="imageLogoError()"
    (load)="imageLogoLoaded()"
    [src]="logo"
  />
  <!-- Start Skeleton loader -->
  <div
    style="width: 80px; height: 60px"
    *ngIf="isLoadingLogo"
    [medSkeletonLoading]="true"
  ></div>
  <!-- End Skeleton loader -->
</div>
