<div class="library-item" [ngClass]="{ 'library-item--row-list': isRowList }">
  <div
    class="card library-item__item"
    (click)="editLibraryFile(libraryItem)"
    *ngIf="libraryItem.type !== libraryTypes.folder"
  >
    <div class="library-item__header">
      <div class="library-item__info">
        <h4
          class="library-item__title line-clamp-1"
          [title]="libraryItem.content.title"
        >
          {{ libraryItem.content.title }}
        </h4>
        <span
          class="library-item__description line-clamp-2"
          *ngIf="isRowList"
          [innerHtml]="libraryItem.content.description"
        ></span>
      </div>
      <button
        id="library-item-option"
        [matMenuTriggerFor]="filemenu"
        (click)="$event.stopPropagation()"
        class="btn btn--sm btn--no-bg btn--icon library-item__options-icon"
      >
        <fa-icon [icon]="optionsIcon"></fa-icon>
      </button>
      <mat-menu #filemenu="matMenu">
        <button
          mat-menu-item
          (click)="downloadItem(libraryItem.fileHash)"
          *ngIf="libraryItem.fileHash"
        >
          <span> {{ 'DOWNLOAD' | translate }} </span>
        </button>
        <button
          mat-menu-item
          (click)="editLibraryFile(libraryItem)"
          *ngIf="canEdit"
        >
          <span> {{ 'EDIT' | translate }} </span>
        </button>
        <button
          mat-menu-item
          (click)="deleteItem(libraryItem.id)"
          *ngIf="canEdit"
        >
          <span> {{ 'DELETE' | translate }} </span>
        </button>
        <button mat-menu-item>
          <span> {{ 'CANCEL' | translate }} </span>
        </button>
      </mat-menu>
    </div>

    <div class="library-item__thumbnail">
      <fa-icon
        class="library-item__icon-file"
        *ngIf="!imageUrl"
        [icon]="fileIcon"
      ></fa-icon>
      <img
        class="library-item__img"
        *ngIf="imageUrl"
        alt=""
        src="{{ imageUrl }}"
      />
    </div>
  </div>

  <div
    class="card library-item__item library-item__card-folder"
    *ngIf="libraryItem.type === libraryTypes.folder"
    (click)="goToFolder(libraryItem.slug)"
  >
    <div class="library-item__header">
      <div class="library-item__info">
        <span
          class="library-item__title line-clamp-1"
          [title]="libraryItem.content.name"
          >{{ libraryItem.content.name }}</span
        >
      </div>
      <button
        id="library-item-option"
        *ngIf="canEdit"
        class="btn btn--sm btn--no-bg btn--icon library-item__options-icon"
        [matMenuTriggerFor]="foldermenu"
        (click)="$event.stopPropagation()"
      >
        <fa-icon [icon]="optionsIcon"></fa-icon>
      </button>
      <mat-menu #foldermenu="matMenu">
        <button mat-menu-item (click)="goToFolder(libraryItem.slug)">
          <span> {{ 'OPEN' | translate }} </span>
        </button>
        <button mat-menu-item (click)="deleteItem(libraryItem.id)">
          <span> {{ 'DELETE' | translate }} </span>
        </button>
        <button mat-menu-item>
          <span> {{ 'CANCEL' | translate }} </span>
        </button>
      </mat-menu>
    </div>

    <div class="library-item__thumbnail">
      <fa-icon class="library-item__icon-file" [icon]="folderIcon"></fa-icon>
    </div>
  </div>
</div>
