<div
  class="card survey-card"
  (click)="goToSurveyDetail(); $event.stopPropagation()"
>
  <div class="survey-card__img-container">
    <div class="survey-card__overlay"></div>

    <img
      [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
      (error)="hiddenImage = true; isLoadingImage = false"
      (load)="isLoadingImage = false"
      class="survey-card__cover"
      src="{{ imageUrl }}"
    />

    <!-- Skeleton image start -->
    <div
      [medSkeletonLoading]="true"
      *ngIf="isLoadingImage"
      class="survey-card__cover"
    ></div>
    <!-- Skeleton image end -->
  </div>

  <div class="survey-card__title-header">
    <h2
      class="survey-card__title"
      title="{{ survey?.content?.content?.title }}"
    >
      {{ survey?.content?.content?.title }}
    </h2>
    <small class="survey-card__subtitle">
      {{ survey?.createdAt | date : 'mediumDate' }}
    </small>
  </div>

  <div class="survey-card__content">
    <button
      id="bt__survey-answer"
      class="btn btn--sm"
      (click)="goToSurveyDetail(); $event.stopPropagation()"
    >
      {{ 'ANSWER' | translate }}
    </button>
  </div>
</div>
