import { ProfileEntity } from '../profile/profile-entity';

export class ProgramResults {
  public answerOrder: number;
  public chainId: string;
  public chainType: string;
  public description: string;
  public label: string;
  public name: string;
  public priority: string;
  public projectId: string;
  public projectName: string;
  public question: string;
  public value: string;
  public responderId: string;
  public responder: ProfileEntity;
}
