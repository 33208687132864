import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavigationExtras, Router } from '@angular/router';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { ArticleMenu } from '../../../common/enums/article/article-menu';
import { ArticleVisibility } from '../../../common/enums/article/article-visibility';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { HubInstance } from '../../../common/models/hub/hub-instance';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ApiPagesService } from '../../../services/api/api-pages.service';
import { ApiProfileV3Service } from '../../../services/api/api-profile-v3.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonService } from '../../../services/common.service';
import { InstanceService } from '../../../services/instance.service';
import { ProfileService } from '../../../services/profile.service';
import { ToastService } from '../../../services/toast.service';
import { ProfileWidgetComponent } from '../profile-widget/profile-widget.component';
import { MatMenuModule } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { Routes } from '../../../common/constants/routes.constants';
import { FormsModule } from '@angular/forms';
import {
  faBars,
  faChevronDown,
  faGlobe,
  faMagnifyingGlass,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { MenuData } from '../../../common/models/side-bar/menu-data';
import { ModalService } from '@meplis/services';
import { LanguageSwitcherModalComponent } from '../language-switcher-modal/language-switcher-modal.component';

@Component({
  selector: 'mec-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    SkeletonDirective,
    SideBarComponent,
    ProfileWidgetComponent,
    MatMenuModule,
    BreadcrumbsComponent,
    FormsModule,
  ],
  providers: [InstanceService, ApiProfileV3Service, ApiPagesService],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentRoute: string;
  public isLogged: boolean;
  public logo: string;

  public hiddenAvatar: boolean;
  public hiddenLogo: boolean;
  public isLoadingAvatar: boolean;
  public isLoadingLogo: boolean;
  public isLoadingMenus: boolean;

  public mobileMenuOpen: boolean;
  public mobileSearchOpened: boolean;

  public instance: HubInstance;
  public loginSubscription: Subscription;
  public profileSubscription: Subscription;
  public routerSubscription: Subscription;
  public searchFilter: string;

  public menuData: MenuData = new MenuData();

  public closeIcon = faXmark;
  public menuIcon = faBars;
  public searchIcon = faMagnifyingGlass;
  public userIcon = faUser;
  public arrowDown = faChevronDown;
  public globeIcon = faGlobe;

  constructor(
    public _commonService: CommonService,
    private _authService: AuthenticationService,
    private _instanceService: InstanceService,
    private _profileService: ProfileService,
    private _router: Router,
    private _toastService: ToastService,
    private _modalService: ModalService
  ) {
    this.routerSubscription = this._router.events.subscribe(() => {
      const routeArray = this._router.url.split('/');
      this.currentRoute = routeArray[routeArray.length - 1];
    });
    this.loginSubscription = this._authService.onLoggedInOut.subscribe(() => {
      this._handleInitialization(true);
    });

    this.profileSubscription = this._profileService.profileSubject.subscribe(
      async () => {
        if (this.isLogged) {
          await this._getProfile();
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.profileSubscription?.unsubscribe();
  }

  public async ngOnInit(): Promise<void> {
    await this._handleInitialization();
  }

  public async openLanguageSwitcherModal(): Promise<void> {
    const modalRef = await this._modalService.openModal(
      LanguageSwitcherModalComponent,
      null,
      null
    );
    modalRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  private async _handleInitialization(isUpdate?: boolean): Promise<void> {
    this.menuData = {
      menuItems: [],
    };

    this.isLoadingMenus = true;
    this.isLoadingAvatar = true;

    if (!isUpdate) {
      this.isLoadingLogo = true;
    }

    this.isLogged = this._authService.isLogged;

    await this._getHubInstance();
    this._getPagesMenus();

    if (!this.isLogged) {
      this.isLoadingAvatar = false;
      return;
    }
    await this._getProfile();
  }

  private async _getPagesMenus(): Promise<void> {
    try {
      const instance: HubInstance = JSON.parse(JSON.stringify(this.instance));

      let menusItems = CommonHelper.orderByPropertyValue(
        instance?.menus,
        'order'
      );

      menusItems = menusItems.map((menu) => {
        if (menu.tag) {
          menu.targetSlug = `tags/${menu.targetSlug}`;
        }
        menu.items.forEach((item) => {
          if (item.tag) {
            item.targetSlug = `tags/${item.targetSlug}`;
          }
        });
        return menu;
      });

      const visibility = this.isLogged
        ? ArticleVisibility.LOGGED_IN
        : ArticleVisibility.NOT_LOGGED_IN;

      const menusFiltered = menusItems.filter(
        (item) =>
          (item.menu === ArticleMenu.BOTH ||
            item.menu === ArticleMenu.TOP_MENU) &&
          (item.visibility === visibility ||
            item.visibility === ArticleVisibility.BOTH)
      );

      this.menuData.menuItems.push(...menusFiltered);
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoadingMenus = false;
  }

  private async _getProfile(): Promise<void> {
    try {
      const profile = await this._profileService.getProfile();

      if (profile.avatar) {
        this.menuData.imageAvatarUrl = CommonHelper.getAvatarImage(profile.id);
      }
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isLoadingAvatar = false;
  }

  private async _getHubInstance(): Promise<void> {
    try {
      this.instance = await this._instanceService.getInstance();
      this.logo = CommonHelper.getUrlFile(
        this.instance.defaultImageHash,
        '&width=500'
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public imageLogoError(): void {
    this.hiddenLogo = true;
  }

  public imageLogoLoaded(): void {
    this.isLoadingLogo = false;
    this.hiddenLogo = false;
  }

  public imageAvatarError(): void {
    this.hiddenAvatar = true;
  }

  public imageAvatarLoaded(): void {
    this.isLoadingAvatar = false;
    this.hiddenAvatar = false;
  }

  public toogleSearchMobile(): void {
    this.mobileSearchOpened = !this.mobileSearchOpened;
  }

  public toogleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  public search(): void {
    if (!this.searchFilter || this.searchFilter === '') {
      return;
    }

    const extras: NavigationExtras = {
      queryParams: {
        filter: this.searchFilter,
      },
      replaceUrl: true,
    };

    this._commonService.navigate([Routes.search.fullPath], extras);
  }

  public goToPage(path: string): void {
    if (path === 'logout') {
      const logoutResult = this._authService.logOut(Routes.home.fullPath);
      if (logoutResult) {
        this._toastService.showSuccess('AUT.FEEDBACK.OPEN.LOGOUT.200.SUCCESS');
      }
      return;
    }

    this._commonService.navigate([path]);
  }
}
