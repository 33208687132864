import { ProgramFileType } from '../../enums/care-monitor/program-file-type';

export class ProgramValidation {
  public validationType: string;
  public minValue?: number;
  public maxValue?: number;
  public minDescription?: string;
  public maxDescription?: string;
  public minLength?: number;
  public maxLength?: number;
  public type?: ProgramFileType;
}
