import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ModalComponent } from '../../common/modal/modal.component';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { AutocompleteItem } from '../../../common/models/common/autocomplete/autocomplete-item';
import { PaginationOptions } from '../../../common/models/common/pagination-options';
import { ToastService } from '../../../services/toast.service';
import { ApiSearchService } from '../../../services/api/api-search.service';
import { AutocompleteComponent } from '../../common/autocomplete/autocomplete.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConstants } from '../../../common/constants/app.constants';
import { SearchResult } from '../../../common/models/common/search/search-result';
import { SocialContentCardComponent } from '../social-content-card/social-content-card.component';
import { DirectionOptions } from '../../../common/enums/common/direction-options';

@Component({
  selector: 'mec-social-content-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TranslateModule,
    SkeletonDirective,
    AutocompleteComponent,
    SocialContentCardComponent,
  ],
  templateUrl: './social-content-modal.component.html',
  styleUrls: ['./social-content-modal.component.scss'],
})
export class SocialContentModalComponent implements OnInit {
  public modalOptions: ModalOptions;

  public searchList: SearchResult[] = [];
  public searchItemSelected: SearchResult;

  public contentAutocompleteItems: AutocompleteItem[] = [];
  public formGroup: FormGroup;

  public isLoading: boolean;

  public paginationOptions: PaginationOptions = {
    limit: 20,
    direction: DirectionOptions.desc,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public socialNetworkId: string,
    public matDialogRef: MatDialogRef<SocialContentModalComponent>,
    private _apiSearchService: ApiSearchService,
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _formBuilder: FormBuilder
  ) {
    this.formGroup = this._formBuilder.group({
      search: [''],
    });

    this.modalOptions = {
      title: this._translateService.instant('APP_STATES.MODAL.TITLE'),
      closeable: true,
      showSecondaryNegativeButton: false,
      showSecondaryPositiveButton: false,
      showNegativeButton: false,
      positiveButtonLabel: 'APP_STATES.MODAL.SELECT',
    };
  }

  public async ngOnInit(): Promise<void> {
    await this.getContentItems('');
  }

  public confirmSelectedContent(): void {
    this.matDialogRef.close(this.searchItemSelected);
  }

  public setContent(itemSelected: AutocompleteItem): void {
    this.searchItemSelected = this.searchList.find(
      (item) => item.id === itemSelected.id
    );
  }

  public async getContentItems(searchFilter: string): Promise<void> {
    this.isLoading = true;
    try {
      this.searchList = await this._apiSearchService.getSearchResults(
        searchFilter,
        this.paginationOptions,
        this.socialNetworkId
      );

      this.contentAutocompleteItems = this.searchList.map((item) => {
        const pageTypeLabel = AppConstants.pageTypes.find(
          (pageType) => pageType.type === item.type
        ).label;

        return {
          title: item.title,
          subtitle: this._translateService.instant(pageTypeLabel),
          id: item.id,
        };
      });
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }
}
