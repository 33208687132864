<mec-modal [options]="modalOptions" (closeClick)="closeModal()">
  <div class="language-switcher">
    <div class="language-switcher__header">
      <img class="language-switcher__map-image" [src]="mapImage" />
      <h3 class="language-switcher__choose-label">
        {{ 'LANGUAGE.CHOOSE_YOUR_LANGUAGE' | translate }}
      </h3>
    </div>
    <div class="language-switcher__groups">
      <div
        *ngFor="let region of regions"
        class="language-switcher__groups-list"
      >
        <h4 *ngIf="region.slug === 'ww'" class="language-switcher__group-label">
          {{ 'LANGUAGE.GLOBAL_SITE' | translate }}
        </h4>
        <h4 *ngIf="region.slug !== 'ww'" class="language-switcher__group-label">
          {{ region.name | translate }}
        </h4>
        <div class="language-switcher__regions">
          <div
            (click)="setLanguageAndRegion(language.language, region.slug)"
            *ngFor="let language of region.languagesFilter"
            class="language-switcher__region"
            [ngClass]="{
              'language-switcher__region--selected':
                region.slug === currentRegion &&
                language.language === currentLanguage
            }"
          >
            <ng-container *ngIf="region.slug === 'ww'">
              <span class="language-switcher__region-label">{{
                language.name | translate
              }}</span>
            </ng-container>
            <ng-container *ngIf="region.slug !== 'ww'">
              <span class="language-switcher__region-label">{{
                language.countryName | translate
              }}</span>
              <span class="language-switcher__region-label" *ngIf="region.name">
                &nbsp;({{ language.name | translate }})</span
              ></ng-container
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</mec-modal>
