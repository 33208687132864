import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ProgramValue } from '../../../common/models/care-monitor/program-value';
import { TranslateModule } from '@ngx-translate/core';
import {
  AngularSignaturePadModule,
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgramAnswer } from '@meplis/common';

@Component({
  selector: 'mec-program-policy',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule,
    AngularSignaturePadModule,
  ],
  templateUrl: './program-policy.component.html',
  styleUrls: ['./program-policy.component.scss'],
})
export class ProgramPolicyComponent implements OnChanges, AfterViewInit {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<{
    valid: boolean;
    programLog: ProgramLog;
  }>();

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300,
  };

  public value: string;
  public values: ProgramValue[] = [
    {
      label: 'COMPONENT.CHAIN_POLICY.AGREE',
      name: 'COMPONENT.CHAIN_POLICY.AGREE',
      value: '1',
    },
    {
      label: 'COMPONENT.CHAIN_POLICY.DISAGREE',
      name: 'COMPONENT.CHAIN_POLICY.DISAGREE',
      value: '0',
    },
  ];

  public clearIcon = faRotateLeft;
  public signatureMetadata: string;

  constructor() {}

  public ngAfterViewInit(): void {
    this.signaturePad.set('minWidth', 5);
    this.signaturePad.clear();
  }

  public ngOnChanges(): void {}

  public clearSignature(): void {
    this.signaturePad.clear();
  }

  public drawComplete(event: MouseEvent | Touch) {
    this.signatureMetadata = this.signaturePad.toDataURL();
    this.selectItem(this.value);
  }

  public selectItem(value: string): void {
    this.value = value;

    const results: ProgramAnswer[] = [
      {
        name: this.programNode.variable.name,
        value: value === '1' ? true : false,
      },
    ];

    if (this.signatureMetadata) {
      results[0].metadata = { signature: this.signatureMetadata };
    }

    const valid =
      value !== '1' || (value === '1' && this.signatureMetadata !== undefined);

    const programLog: ProgramLog = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: results,
    };

    this.onSelectItemChange.emit({ valid, programLog });
  }
}
