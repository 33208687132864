import {
  AuthenticationService,
  CommonService,
  GTMService,
  Hub,
  InstanceService,
  ProfileService,
} from '@meplis/services';
import { BASE_PATH } from './app.routes';
import { Location } from '@angular/common';
import { CustomHubConfiguration } from '@meplis/common';
import {
  GoogleTagManagerConfig,
  GoogleTagManagerService,
} from 'angular-google-tag-manager';

const HEADER_X_MEPL_CAMPUS: any = 'x-mepl-campus';

export function appInitializer(
  authService: AuthenticationService,
  commonService: CommonService,
  gtmService: GTMService,
  hubService: Hub,
  instanceService: InstanceService,
  location: Location
): () => Promise<void> {
  return async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (complete) => {
      const path = location.path();
      let configuration;

      if (path === '') {
        configuration = await setHubConfiguration();
        const hubInstanceWithHeaders =
          await instanceService.getInstanceWithHeaders({
            'x-mepl-campus': configuration.slug,
          });
        const language = hubInstanceWithHeaders.headers.get(
          'X-Mepl-Region-Language'
        );
        const region = hubInstanceWithHeaders.headers.get('X-Mepl-Region-Slug');
        await commonService.handleBaseUrl(configuration.slug, region, language);
      } else {
        hubService.setBasePath(BASE_PATH);
        configuration = await setHubConfiguration();
        await commonService.handleBaseUrl(configuration.slug);
      }

      const hubInstance = await instanceService.getInstance();

      if (commonService.isPlatformBrowser) {
        await authService.handleAuthentication();

        const ga4Setting = hubService.getSetting('GOOGLE_ANALYTICS_CODE_GA4');
        // const options: GoogleTagManagerConfig = {
        //   id: ga4Setting || null,
        //   // debug_mode: 'true',
        //   // send_page_view: 'false',
        //   // gtm_auth: ``,
        //   // gtm_preview: ``,
        //   // gtm_resource_path: ``,
        // };

        // gtmService.setConfig(options);
        // gtmService.init(options);
        // gtmService.tag('js', new Date());
        // gtmService.tag('config', ga4Setting);

        gtmService.setTagManagerScript(ga4Setting);
      }

      commonService.setSEOConfiguration(hubInstance);
      commonService.init();
      await commonService.setTheme();

      complete();
    });

    async function setHubConfiguration(): Promise<CustomHubConfiguration> {
      const basePathParts = BASE_PATH.split('/');

      let configuration;
      let hubSlug;

      if (!hubSlug) {
        if (commonService.isPlatformServer) {
          hubSlug = process.env[HEADER_X_MEPL_CAMPUS];
        } else {
          if (basePathParts.length === 2) {
            if (window[HEADER_X_MEPL_CAMPUS]) {
              hubSlug = window[HEADER_X_MEPL_CAMPUS] as unknown as string;
            } else {
              hubService.setHub();
              hubSlug = hubService.hub();
            }
          } else {
            hubService.setHub();
            hubSlug = hubService.hub();
          }
        }
      }

      try {
        configuration = (await import(
          `./hubs/hub-configuration.${hubSlug}`
        )) as any;
      } catch (errorHubConfiguration) {
        try {
          if (commonService.isPlatformServer) {
            // mleon(): THIS CANNOT BE CHANGED / REMOVED,
            //          IT IS REPLACED BY ANGULAR BUILDER
            configuration = (await import(
              `./hubs/hub-configuration.hub-meplis`
            )) as any;

            hubSlug = configuration.hubConfiguration.slug;
          } else if (hubSlug) {
            configuration = {
              hubConfiguration: new CustomHubConfiguration(hubSlug),
            };
          }
        } catch (errorHubConfigurationFallback) {
          console.error(errorHubConfiguration);
          console.error(errorHubConfigurationFallback);
          throw Error('APP INITIALIZER ERROR: NO CAMPUS FOUND');
        }
      }

      if (configuration) {
        hubService.setFixedHub(configuration.hubConfiguration.slug);
        hubService.setHubConfiguration(configuration.hubConfiguration);
        hubService.setHubStyles(configuration.hubConfiguration);
      } else if (hubSlug) {
        hubService.setFixedHub(hubSlug);
      } else {
        throw Error('APP INITIALIZER ERROR: NO CAMPUS FOUND');
      }

      return configuration.hubConfiguration;
    }
  };
}
