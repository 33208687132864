<form class="program-number">
  <h4>{{ programNode?.labelText }}</h4>
  <div class="program-number__options">
    <input
      [formControl]="formControlNumber"
      [value]="formControlNumber.value"
      type="number"
      class="field__input"
    />
    <mat-slider
      *ngIf="programNode?.slider"
      [min]="this.programNode.validation.minValue"
      [max]="this.programNode.validation.maxValue"
      [step]="step"
    >
      <input
        matSliderThumb
        [value]="formControlNumber.value"
        [formControl]="formControlNumber"
      />
    </mat-slider>
  </div>
  <span>
    {{ 'COMPONENT.CHAIN_NUMBER.HELP.BETWEEN' | translate: { minValue:programNode.validation.minValue,  maxValue:programNode.validation.maxValue, } }}
  </span>
</form>
