import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appInitializer } from './app.initializer';
import { TranslateModule } from '@ngx-translate/core';
import {
  AlertService,
  CommonService,
  ModalService,
  TokenInterceptor,
  AuthenticationService,
  InstanceService,
  MepStorage,
  PlatformService,
  StorageWebService,
  MepFile,
  FileWebService,
  Hub,
  HubWebService,
  GTMService,
  ProfileService,
} from '@meplis/services';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatDialogModule } from '@angular/material/dialog';
import {
  DynamicHtmlModule,
  FilepickerInsertComponent,
  SocialExternalLinkComponent,
  SocialHashtagLinkComponent,
  SocialProfileLinkComponent,
} from '@meplis/components';
import { Location } from '@angular/common';
import {
  GoogleTagManagerConfiguration,
  GoogleTagManagerModule,
} from 'angular-google-tag-manager';
import { environment } from '@meplis/environments';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      // withDebugTracing(),
      inMemoryScrollingFeature,
      withEnabledBlockingInitialNavigation()
    ),
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [
        AuthenticationService,
        CommonService,
        GTMService,
        Hub,
        InstanceService,
        Location,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    importProvidersFrom(
      AlertService,
      DynamicHtmlModule.forRoot({
        components: [
          {
            component: FilepickerInsertComponent,
            selector: 'filepicker-insert',
          },
          {
            component: SocialProfileLinkComponent,
            selector: 'mec-social-profile-link',
          },
          {
            component: SocialHashtagLinkComponent,
            selector: 'mec-social-hashtag-link',
          },
          {
            component: SocialExternalLinkComponent,
            selector: 'mec-social-external-link',
          },
        ],
      }),
      FontAwesomeModule,
      GoogleTagManagerModule.forRoot(),
      HttpClientModule,
      MatDialogModule,
      MatSnackBarModule,
      ModalService,
      NgCircleProgressModule.forRoot({
        radius: 60,
        space: -9,
        maxPercent: 100,
        outerStrokeGradient: true,
        showTitle: true,
        showSubtitle: false,
        lazy: false,
      }),
      TranslateModule.forRoot()
    ),
    {
      provide: MepStorage,
      deps: [PlatformService],
      useClass: StorageWebService,
    },
    {
      provide: MepFile,
      useClass: FileWebService,
    },
    {
      provide: Hub,
      useClass: HubWebService,
    },
  ],
};
