import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Program } from '../../../common/models/care-monitor/program';
import { ProgramStatus } from '../../../common/enums/care-monitor/program-status';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { CommonService } from '../../../services/common.service';
import { ToastService } from '@meplis/services';

@Component({
  selector: 'mec-programs-item',
  standalone: true,
  imports: [CommonModule, TranslateModule, SkeletonDirective],
  templateUrl: './programs-item.component.html',
  styleUrls: ['./programs-item.component.scss'],
})
export class ProgramsItemComponent implements OnChanges {
  @Input() public program: Program;

  public imageUrl: string;
  public programsStatus = ProgramStatus;
  public isLoadingImage: boolean = true;
  public hiddenImage: boolean;
  public isPolicy: boolean;

  constructor(
    private _commonService: CommonService,
    private _toastService: ToastService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.program) {
      if (this.program.project?.icon) {
        this.imageUrl = CommonHelper.getFileUrl(this.program.project?.icon);
      }

      this.isPolicy = this.program?.metadata?.group.includes('POLICY');

      switch (this.program.statusEnum) {
        case ProgramStatus.UPDATED:
          this.program.statusLabel = 'CONTINUE';
          break;
        case ProgramStatus.FINISHED:
          this.program.statusLabel = 'PROGRAM.FINISHED';
          break;
        case ProgramStatus.NOT_EXECUTED:
          this.program.statusLabel = 'PROGRAM.NOT_EXECUTED';
          break;
        case ProgramStatus.NOT_APPLICABLE:
          this.program.statusLabel = 'PROGRAM.NOT_APPLICABLE';
          break;
        case ProgramStatus.PAUSED:
          this.program.statusLabel = 'PROGRAM.PAUSED';
          break;
        default:
          this.program.statusLabel = 'START';
          break;
      }
    }
  }

  public goToProgram(): void {
    switch (this.program.statusEnum) {
      case ProgramStatus.FINISHED ||
        ProgramStatus.NOT_APPLICABLE ||
        ProgramStatus.PAUSED:
        this._commonService.navigate(['program', 'show', this.program?.token]);
        break;
      case ProgramStatus.NOT_EXECUTED:
        this._toastService.showInfo('PROGRAMS.ANSWERED.NOT_EXECUTED.MESSAGE');
        break;
      default:
        this._commonService.navigate(['program', 'run', this.program?.token]);

        break;
    }
  }

  public imageError(): void {
    this.hiddenImage = true;
  }

  public imageLoaded(): void {
    this.isLoadingImage = false;
    this.hiddenImage = false;
  }
}
