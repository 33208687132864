import { ProgramNode } from './program-node';
import { ProgramStepData } from './program-step-data';

export class ProgramProject {
  public availableLanguages: string[];
  public description: string;
  public icon: number;
  public isGroup: boolean;
  public isPublic: boolean;
  public languages: string;
  public lastUpdate: Date;
  public nodeDataArray: ProgramNode[];
  public stepDataArray: ProgramStepData[];
  public origin: string;
  public profile: number;
  public progressBarEnabled: boolean;
  public reference: string;
  public results: string;
  public shares: number[];
  public slug: string;
  public startChainId: string;
  public status: number;
  public title: string;
  public token: string;
  public countSteps: number;
}
