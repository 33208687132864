import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';

@Component({
  selector: 'mec-program-arithmetic',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './program-arithmetic.component.html',
  styleUrls: ['./program-arithmetic.component.scss'],
})
export class ProgramArithmeticComponent implements OnChanges {
  @Input() public programNode: ProgramNode;

  public message: string;

  constructor() {}

  public ngOnChanges(): void {
    if (this.programNode) {
      this.setMessage();
    }
  }

  private setMessage(): void {
    const variables = this._getSentenceVariables(this.programNode.message);
  }

  private _getSentenceVariables(sentence: string): string[] {
    const variables = sentence.match(/\{[^}]+\}/gi);
    return variables;
  }
}
