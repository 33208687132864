import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mec-program-number',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    TranslateModule,
  ],
  templateUrl: './program-number.component.html',
  styleUrls: ['./program-number.component.scss'],
})
export class ProgramNumberComponent implements OnChanges {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<{
    programLog: ProgramLog;
    valid: boolean;
  }>();

  public step: number = 1;

  public formControlNumber: FormControl;
  public subscription: Subscription;

  constructor() {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.programNode) {
      if (this.programNode.decimals) {
        this.step = 1 / Math.pow(10, parseInt(this.programNode.decimals));
      }

      this.formControlNumber = new FormControl();
      this.formControlNumber.setValue(this.programNode?.log?.results[0]?.value);
      this.formControlNumber.setValidators([
        Validators.required,
        Validators.min(this.programNode.validation.minValue),
        Validators.max(this.programNode.validation.maxValue),
        this._stepsValidator,
      ]);

      this.subscription = this.formControlNumber.valueChanges.subscribe(
        (result) => {
          this._changeInput(result, this.formControlNumber.valid);
        }
      );
    }
  }

  private _stepsValidator = (control: AbstractControl) => {
    if (!control.value || control.value % this.step !== 0) {
      return { error: true };
    }
    return null;
  };

  private _changeInput(value: string, valid: boolean): void {
    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.variable.name, value: value }],
    };

    this.onSelectItemChange.emit({
      programLog: log,
      valid,
    });
  }
}
