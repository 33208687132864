import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { FileEntity, FileSubtitleEntity, UploadSignature } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiFilesV3Service extends ApiBaseService {
  protected apiBaseUrl = environment.filesV3;
  protected routePath = ``;

  constructor(private _httpClient: HttpClient, private _hubService: Hub) {
    super(_httpClient);
  }

  public getUploadSignature(): Promise<UploadSignature> {
    const path = `issue-upload-signature`;
    return super.get<UploadSignature>(path);
  }

  public getFileByHash(hash: string): Promise<FileEntity> {
    const path = `${hash}`;
    return super.get<FileEntity>(path);
  }

  public getFileSubtitles(hash: string): Promise<FileSubtitleEntity[]> {
    const path = `files/${hash}/subtitles`;
    return super.get<FileSubtitleEntity[]>(path);
  }

  public getFileSubtitlesByUrl(url: string): Promise<FileSubtitleEntity[]> {
    const path = `subtitles`;
    return super.get<FileSubtitleEntity[]>(path, {
      apiBaseUrl: url,
    });
  }

  public confirmFile(
    token: string,
    filename: string,
    folderPath?: string
  ): Promise<FileEntity[]> {
    let path = `files?path=Campus/${this._hubService.hub()}`;

    const params = {
      token: token,
      filename: filename,
    };

    if (folderPath) {
      path += `/library/${folderPath}`;
    }

    return super.post<FileEntity[]>(path, params);
  }
}
