<div class="programs-item card" (click)="goToProgram()">
  <div class="programs-item__top">
    <div class="programs-item__image-container">
      <img
        class="programs-item__image avatar"
        [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
        *ngIf="imageUrl && !hiddenImage"
        (error)="imageError()"
        (load)="imageLoaded()"
        [src]="imageUrl"
      />
      <!-- Start Skeleton loader -->
      <div
        class="programs-item__image avatar"
        *ngIf="isLoadingImage && imageUrl"
        style="border-radius: 50% !important"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->
      <div class="programs-item__top-info">
        <span class="programs-item__hub">{{ program?.metadata?.tenant }}</span>
        <span class="programs-item__name">
          {{ program?.metadata?.program_name }}
        </span>
      </div>
    </div>
    <div
      *ngIf="!isPolicy || (isPolicy && program?.metadata?.group === 'POLICY')"
      class="programs-item__status"
      [ngClass]="{
        'programs-item__status--completed':
          program?.statusEnum === programsStatus.FINISHED,
        'programs-item__status--paused':
          program?.statusEnum === programsStatus.PAUSED ||
          program?.statusEnum === programsStatus.UPDATED,
        'programs-item__status--not-completed':
          program?.statusEnum === programsStatus.NOT_APPLICABLE ||
          program?.statusEnum === programsStatus.NOT_EXECUTED,
        'programs-item__status--started':
          program?.statusEnum === programsStatus.INIT
      }"
    >
      <span class="programs-item__title-status">{{
        program?.statusLabel | translate
      }}</span>
    </div>
  </div>
  <div class="programs-item__bottom">
    <div class="programs-item__left">
      <span class="programs-item__date">{{
        program?.updatedAt | date : 'mediumDate'
      }}</span>
      <span
        [innerHTML]="program?.project?.description"
        [title]="program?.project?.description"
        class="line-clamp-3 programs-item__description"
      ></span>
    </div>

    <div
      *ngIf="
        program?.metadata?.group === 'POLICY_NOT_ACCEPTED' ||
        program?.metadata?.group === 'POLICY_ACCEPTED'
      "
      class="programs-item__right"
    >
      <span
        class="programs-item__policy-info"
        *ngIf="program?.metadata?.group === 'POLICY_ACCEPTED'"
      >
        {{ 'PROGRAMS.RUN.POPUP_ALERT.PROGRAM_POLICY_ACCEPTED' | translate }}
      </span>
      <span
        class="programs-item__policy-info"
        *ngIf="program?.metadata?.group === 'POLICY_NOT_ACCEPTED'"
      >
        {{ 'PROGRAMS.RUN.POPUP_ALERT.PROGRAM_POLICY_NOT_ACCEPTED' | translate }}
      </span>
    </div>
  </div>
</div>
