export const Routes: any = {
  home: { title: 'HOME', fullPath: 'home' },
  notFound: { title: 'NOT_FOUND', fullPath: 'not-found' },
  login: { title: 'LOGIN', fullPath: 'login' },
  authentication: { title: 'AUTHENTICATION', fullPath: 'authentication' },
  signUp: { title: 'SIGN_UP', fullPath: 'sign-up' },
  recoveryPassword: {
    title: 'AUT.RECOVER_PASSWORD.RECOVER.TITLE',
    fullPath: 'recovery-password',
  },
  news: { title: 'NEWS', fullPath: 'news' },
  newsDetail: { title: 'NEWS', fullPath: 'news/:slug' },
  courses: { title: 'COURSES', fullPath: 'courses' },
  course: { title: 'COURSE', fullPath: 'course/:slug' },
  page: { title: 'PAGES', fullPath: 'page/:slug' },
  blog: { title: 'BLOG', fullPath: 'blog/:slug' },
  event: { title: 'EVENT', fullPath: 'event/:slug' },
  events: { title: 'EVENTS', fullPath: 'events' },
  cookiePolicy: { title: 'COOKIE_POLICY', fullPath: 'cookie-policy' },
  disclaimer: { title: 'DISCLAIMER', fullPath: 'disclaimer' },
  privacyPolicy: { title: 'PRIVACY_POLICY', fullPath: 'privacy-policy' },
  legalNotice: { title: 'LEGAL_NOTICE', fullPath: 'legal-notice' },
  termsOfService: { title: 'TERMS_OF_SERVICE', fullPath: 'terms-of-service' },
  profile: { title: 'PROFILE', fullPath: 'profile' },
  programs: {
    title: 'COMPONENT.MAIN_MENU.ITEM.PROGRAMS',
    fullPath: 'programs',
  },
  program: {
    title: 'COMPONENT.MAIN_MENU.ITEM.PROGRAMS',
    fullPath: 'program/run/:id',
  },
  programAnswered: {
    title: 'COMPONENT.MAIN_MENU.ITEM.PROGRAMS',
    fullPath: 'program/show/:id',
  },
  evaluations: { title: 'EVALUATIONS', fullPath: 'evaluations' },
  evaluation: {
    title: 'EVALUATION',
    fullPath: 'evaluation/:evaluationId',
  },
  classroom: {
    title: 'COURSE',
    fullPath: 'course/:courseSlug/step/:stepId',
  },
  library: { title: 'LIBRARY', fullPath: 'library' },
  libraryFolder: {
    title: 'Library folder', //todo translate
    fullPath: 'library-folder/:folderfullPath',
  },
  feed: { title: 'FEED', fullPath: 'feed' },
  dashboard: { title: 'DASHBOARD', fullPath: 'dashboard' },
  socialParticipants: {
    title: 'PARTICIPANTS',
    fullPath: 'social-participants',
  },
  contact: { title: 'CONTACT', fullPath: 'contact' },
  about: { title: 'ABOUT', fullPath: 'about' },
  search: { title: 'SEARCH', fullPath: 'search' },
  socialNetworkGroups: {
    title: 'SOCIALNETWORK.GROUPS',
    fullPath: 'network/:networkId',
  },
  socialSearch: {
    title: 'SOCIALNETWORK.PARTICIPANTS.MANAGEMENT',
    fullPath: 'participants/management/list',
  },
  participantsCommunity: {
    title: 'SOCIALNETWORK.PARTICIPANTS.COMMUNITY',
    fullPath: 'participants/community',
  },
  participantsProfile: {
    title: 'SOCIALNETWORK_SOCIAL_PROFILE',
    fullPath: 'participants/profile/:profileId',
  },
  participantsManagement: {
    title: 'SOCIALNETWORK.PARTICIPANTS.MANAGEMENT',
    fullPath: 'participants/management/list',
  },
  tags: {
    title: 'TAGS',
    fullPath: 'tags/:slug',
  },
  actions: {
    title: 'MY_ACTIONS',
    fullPath: 'actions',
  },
  actionsAnswer: {
    title: 'ACTIONS_ANSWER',
    fullPath: 'actions/:id',
  },
  myCertificates: {
    title: 'MY_CERTIFICATES',
    fullPath: 'my-certificates',
  },
  mySavedItems: {
    title: 'MY_SAVED_ITEMS',
    fullPath: 'my-saved-items',
  },
};
