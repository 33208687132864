import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiBaseService {
  protected abstract apiBaseUrl: string;
  protected abstract routePath: string;
  constructor(private httpClient: HttpClient) {}

  protected get<TResult>(
    path: string,
    options?: ApiBaseOptions
  ): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
      const url = this.buildUrl(path, options);
      return this.httpClient.get<TResult>(url, options as unknown).subscribe({
        next: (result) => {
          resolve(result);
        },
        error: (errorResult) => {
          reject(errorResult?.error?.error);
        },
      });
    });
  }

  //use this for few specif cases
  protected getObservable<TResult>(
    path: string,
    options?: ApiBaseOptions
  ): Observable<TResult> {
    const url = this.buildUrl(path, options);
    return this.httpClient.get<TResult>(url, options as unknown);
  }

  protected post<TResult>(
    path: string,
    content?: any,
    options?: ApiBaseOptions
  ): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
      const url = this.buildUrl(path, options);
      if (options) {
        options = this.prepareOptions(options);
      }

      return this.httpClient
        .post<TResult>(url, content, options as unknown)
        .subscribe({
          next: (result) => resolve(result),
          error: (errorResult) => {
            reject(errorResult?.error?.error);
          },
        });
    });
  }

  protected put<TResult>(
    path: string,
    content?: any,
    options?: ApiBaseOptions
  ): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
      const url = this.buildUrl(path, options);

      if (options) {
        options = this.prepareOptions(options);
      }

      return this.httpClient
        .put<TResult>(url, content, options as unknown)
        .subscribe({
          next: (result) => resolve(result),
          error: (errorResult) => {
            reject(errorResult?.error?.error);
          },
        });
    });
  }

  protected patch<TResult>(
    path: string,
    content?: any,
    options?: ApiBaseOptions
  ): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
      const url = this.buildUrl(path, options);

      if (options) {
        options = this.prepareOptions(options);
      }

      return this.httpClient
        .patch<TResult>(url, content, options as unknown)
        .subscribe({
          next: (result) => resolve(result),
          error: (errorResult) => reject(errorResult?.error.error),
        });
    });
  }

  protected delete<TResult>(
    path: string,
    options?: ApiBaseOptions
  ): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
      const url = this.buildUrl(path, options);

      if (options) {
        options = this.prepareOptions(options);
      }

      return this.httpClient
        .delete<TResult>(url, options as unknown)
        .subscribe({
          next: (result) => resolve(result),
          error: (error) => reject(error.message),
        });
    });
  }

  private prepareOptions(options: ApiBaseOptions): ApiBaseOptions {
    //TODO: VERIFY WHAT IS NECESSARY WHEN INTEGRATE WITH API
    if (options.headers) {
      options.headers = Object.assign(
        { 'Accept-Language': 'en' },
        options.headers
      );
    }

    return options;
  }

  private buildUrl(path: string, options: ApiBaseOptions): string {
    options = options || {};

    let url = options.apiBaseUrl || this.apiBaseUrl;

    if (!url.endsWith('/')) {
      url += '/';
    }

    url += this.routePath;

    if (!path) {
      return url;
    }

    if (!url.endsWith('/') && !path.startsWith('/')) {
      url += '/';
    }

    return url + path;
  }
}

export class ApiBaseOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | number | string[] };
  responseType?: 'json' | 'text';
  observe?: string;
  apiBaseUrl?: string;
}
