import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { Region } from '../../../common/models/common/language/region';
import { InstanceService } from '../../../services/instance.service';
import { ToastService } from '../../../services/toast.service';
import { CommonService } from '../../../services/common.service';
import { i18nService } from '../../../services/i18n.service';
import { LanguagesFilter } from '../../../common/constants/languages-filter';
import { Language } from '../../../common/models/common/language/language';

@Component({
  selector: 'mec-language-switcher-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent, TranslateModule],
  templateUrl: './language-switcher-modal.component.html',
  styleUrls: ['./language-switcher-modal.component.scss'],
})
export class LanguageSwitcherModalComponent implements OnInit {
  public modalOptions: ModalOptions;

  public mapImage: string = 'assets/images/world-map.png';

  public regions: Region[] = [];
  public currentRegion: string;
  public currentLanguage: string;

  constructor(
    private _translateService: TranslateService,
    private _matDialogRef: MatDialogRef<LanguageSwitcherModalComponent>,
    private _instanceService: InstanceService,
    private _toastService: ToastService,
    private _commonService: CommonService,
    private _router: Router,
    private _i18nService: i18nService
  ) {
    this.modalOptions = {
      title: this._translateService.instant('LANGUAGE.REGIONS_AND_LANGUAGES'),
      closeable: true,
      showNegativeButton: false,
      showSecondaryNegativeButton: false,
      showPositiveButton: false,
      showSecondaryPositiveButton: false,
    };

    this.currentRegion = this._i18nService.region;
    this.currentLanguage = this._i18nService.language;
  }

  public async ngOnInit(): Promise<void> {
    await this._getRegions();
  }

  private async _getRegions(): Promise<void> {
    try {
      let regionsResult = await this._instanceService.getRegions();

      this.regions = regionsResult.map((region) => {
        const languagesFilter = LanguagesFilter.filter((lang) =>
          region.languages.includes(lang.language)
        );
        return {
          ...region,
          languagesFilter,
        };
      });
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public setLanguageAndRegion(language: Language, region: string): void {
    const currentBasePath = this._commonService.getBasePath();
    const basePath = this._commonService.getBasePath(region, language);
    let nextRoute = this._router.url.replace(currentBasePath, basePath);

    if (!nextRoute || nextRoute == '' || nextRoute == '/') {
      nextRoute = basePath + '/home';
    }

    this._commonService.windowOpen(nextRoute, '_self');

    this.closeModal();
  }

  public closeModal(): void {
    this._matDialogRef.close();
  }
}
