import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProgramAnswer } from '../../../common/models/care-monitor/program-answer';
import { ProgramLog } from '@meplis/common';

@Component({
  selector: 'mec-program-open-text',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './program-open-text.component.html',
  styleUrls: ['./program-open-text.component.scss'],
})
export class ProgramOpenTextComponent implements OnDestroy {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<{
    programLog: ProgramLog;
    valid: boolean;
  }>();

  public formControl: FormControl;
  public subscription: Subscription;

  constructor() {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(): void {
    if (this.programNode) {
      this.formControl = new FormControl();
      this.formControl.setValue(this.programNode?.log?.results[0]?.value);
      this.formControl.setValidators([
        Validators.required,
        Validators.minLength(this.programNode.validation.minLength),
        Validators.maxLength(this.programNode.validation.maxLength),
      ]);

      this.subscription = this.formControl.valueChanges.subscribe((result) => {
        this._changeInput(result, this.formControl.valid);
      });
    }
  }

  private _changeInput(value: string, valid: boolean): void {
    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.variable.name, value: value }],
    };

    this.onSelectItemChange.emit({
      programLog: log,
      valid,
    });
  }
}
