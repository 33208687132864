import { ProgramStatus } from '../../enums/care-monitor/program-status';
import { ProgramLog } from './program-log';
import { ProgramNode } from './program-node';

export class ProgramStepResult {
  public current: ProgramNode[];
  public previous: ProgramNode[];
  public logs: ProgramLog[];
  public status: ProgramStatus;
}
