import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mec-program-date',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './program-date.component.html',
  styleUrls: ['./program-date.component.scss'],
})
export class ProgramDateComponent implements OnChanges, OnDestroy {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<{
    programLog: ProgramLog;
    valid: boolean;
  }>();

  public formControl: FormControl;
  public subscription: Subscription;
  public dateTypes: { [key: string]: string } = {
    DATE: 'date',
    DATE_TIME: 'datetime-local',
    TIME: 'time',
  };
  public type: string;

  constructor() {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(): void {
    if (this.programNode) {
      this.type = this.dateTypes[this.programNode?.dateType];

      this.formControl = new FormControl();
      this.formControl.setValue(this.programNode?.log?.results[0]?.value);
      this.formControl.setValidators([Validators.required]);

      this.subscription = this.formControl.valueChanges.subscribe((result) => {
        let valid = this.formControl.valid;

        if (!result || result == '') {
          valid = false;
        }

        if (this.programNode?.dateType === 'TIME') {
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Jan is 0, dec is 11
          const day = date.getDate();
          const dateString = '' + year + '-' + month + '-' + day;
          result = new Date(dateString + ' ' + result).toISOString();
        }

        this._changeInput(result, valid);
      });
    }
  }

  private _changeInput(value: string, valid: boolean): void {
    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.variable.name, value: value }],
    };

    this.onSelectItemChange.emit({
      programLog: log,
      valid,
    });
  }
}
