import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiProfileV3Service } from './api/api-profile-v3.service';
import { ProfileEntity } from '../common/models/profile/profile-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public profileSubject = new BehaviorSubject<ProfileEntity>(
    new ProfileEntity()
  );

  constructor(private _apiProfileV3: ApiProfileV3Service) {}

  public async setProfile(reset?: boolean): Promise<void> {
    if (reset) {
      this.profileSubject.next(new ProfileEntity());
      return;
    }

    const profile = await this._apiProfileV3.getProfile();
    this.profileSubject.next(profile);
  }

  public getProfile(): Promise<ProfileEntity> {
    return new Promise((resolve) => {
      let profile = this.profileSubject.getValue();
      if (Object.keys(profile).length === 0) {
        this.setProfile().then(() => {
          profile = this.profileSubject.getValue();
          resolve(profile);
        });
      } else {
        resolve(profile);
      }
    });
  }

  public async getId(): Promise<number> {
    return new Promise((resolve) => {
      const profile = this.profileSubject.getValue();
      resolve(profile.id);
    });
  }
}
