import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  GoogleTagManagerConfig,
  GoogleTagManagerService,
} from 'angular-google-tag-manager';
import { DOCUMENT, Location } from '@angular/common';
import { GTMTagOptions } from '../common/models/google/google-tag-manager/tag-options';

@Injectable({
  providedIn: 'root',
})
/**
 * Provides a service for interacting with the Google Tag Manager (GTM) service.
 *
 * This service handles the initialization of the GTM script, pushing data to the GTM data layer, and tagging events and page views.
 */
export class GTMService {
  private _isLoaded = false;
  private _debugMode = false;

  private _renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _googleTagManagerService: GoogleTagManagerService,
    private _location: Location,
    private _rendererFactory2: RendererFactory2
  ) {
    this._renderer2 = this._rendererFactory2.createRenderer(null, null);
  }

  /**
   * Initializes the Google Tag Manager (GTM) service with the provided configuration.
   *
   * This method adds the GTM script to the DOM and sets the `_isLoaded` and `_debugMode` properties based on the configuration. If the GTM script is successfully loaded, it also sends a 'config' event with the provided configuration ID, and a 'userId' event with the provided user ID.
   *
   * @param configuration - An optional `GoogleTagManagerConfig` object containing the configuration for the GTM service.
   * @returns A Promise that resolves when the GTM script has been loaded and the initial events have been sent.
   */
  public init(configuration?: GoogleTagManagerConfig) {
    this._googleTagManagerService.addGtmToDom().then(
      (isLoaded: boolean) => {
        this._isLoaded = isLoaded;
        this._debugMode = configuration['debugMode'] === 'true';
        if (!isLoaded) return;
        configuration.id && this.tag('config', configuration.id);
        configuration['userId'] && this.tag('userId', configuration['userId']);
      },
      (error) => {
        if (error === false) return;
        console.error(error);
      }
    );
  }

  public setTagManagerScript(id: string): void {
    let script1 = this._renderer2.createElement('script');
    script1.async = 'true';
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

    let script2 = this._renderer2.createElement('script');
    script2.text = `{
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${id}');
    }`;

    this._renderer2.appendChild(this._document.head, script1);
    this._renderer2.appendChild(this._document.head, script2);
  }

  public setConfig(config: GoogleTagManagerConfig): void {
    this._googleTagManagerService['config'] = config;
  }

  /**
   * Indicates whether the Google Tag Manager service has been successfully loaded.
   * @returns `true` if the Google Tag Manager service has been loaded, `false` otherwise.
   */
  public get isLoaded(): boolean {
    return this._isLoaded;
  }

  /**
   * Retrieves the UTM parameters from the current URL path and returns them as an object.
   *
   * @param path - The path to extract the UTM parameters from. If not provided, the current URL path is used.
   * @param asCampaignVar - If true, the UTM parameters are returned with the corresponding campaign variable names (e.g. `utm_source` -> `campaignSource`).
   * @returns An object containing the UTM parameters extracted from the URL.
   */
  public getUtmParams(path?: string, asCampaignVar?: boolean): object {
    const utmToCampaignVar: { [key: string]: string } = {
      utm_source: 'campaignSource',
      utm_medium: 'campaignMedium',
      utm_term: 'campaignTerm',
      utm_content: 'campaignContent',
      utm_campaign: 'campaignName',
    };
    const output: { [key: string]: string } = {};
    const search =
      (path || this._location.path()).split('?')[1]?.split('&') || [];

    search.forEach((pair) => {
      const pairSplit = pair.split('=');
      const campaignVar = utmToCampaignVar[pairSplit[0]];
      output[(asCampaignVar && campaignVar) || pairSplit[0]] = pair[1];
    });

    return output;
  }

  /**
   * Pushes the provided parameters to the Google Tag Manager data layer.
   *
   * If the GTM service is not yet loaded, it will first load the GTM script before pushing the parameters.
   *
   * @param parameters The parameters to push to the GTM data layer. Can be a mix of strings and objects.
   * @returns A Promise that resolves when the parameters have been pushed to the data layer.
   */
  public async tag(...parameters: (string | object)[]): Promise<void> {
    return new Promise<void>((resolve /* , reject */) => {
      const dataLayer = this._googleTagManagerService.getDataLayer();
      let deferredIsLoaded;

      if (this._isLoaded) {
        deferredIsLoaded = Promise.resolve();
      } else {
        deferredIsLoaded = this._googleTagManagerService.addGtmToDom();
      }

      deferredIsLoaded
        .then(() => {
          dataLayer.push(...parameters);
        })
        .finally(resolve);
    });
  }

  /**
   * Tags an event with GTM.
   * @param options Options for the GTM tag, including the event action and debug mode.
   * @param options.event_action The action for the event being tagged.
   * @param options.debug_mode Whether to enable debug mode for the tag. If not provided, the default debug mode from the service is used.
   */
  public tagEvent(options: GTMTagOptions) {
    if (!options.event_action) {
      return console.error(
        'GTM Service: Invalid action "' + options.event_action + '"'
      );
    }

    options.debug_mode =
      options.debug_mode === 'true' ? 'true' : this._debugMode.toString();

    if (options.debug_mode !== 'true') {
      delete options.debug_mode;
    }

    this.tag('event', options.event_action, options);
  }

  /**
   * Sends a "screen_view" and "page_view" event to Google Tag Manager (GTM) with the provided options.
   *
   * @param options - Optional options for the GTM tag, including the page location, title, path, and referrer.
   * @param options.page_location - The current page location. If not provided, the current path is used.
   * @param options.page_title - The current page title. If not provided, the document title or page location is used.
   * @param options.page_path - The current page path. If not provided, the page location is used.
   * @param options.page_referrer - The current page referrer. If not provided, the document referrer is used.
   */
  public tagView(options?: GTMTagOptions) {
    options = options || ({} as GTMTagOptions);

    options.page_location = options.page_location || this._location?.path();
    options.page_title =
      options.page_title || this._document?.title || options.page_location;
    options.page_path = options.page_path || options.page_location;
    options.page_referrer = options.page_referrer || this._document?.referrer;

    options = Object.assign(options, this.getUtmParams(options.page_location));

    // mleon(): so every event has these settings defined
    this.tag('set', options);
    this.tag('', 'screen_view');
    this.tag('', 'page_view');
  }

  /**
   * Tags a link interaction event with Google Tag Manager (GTM).
   *
   * @param options - Options for the GTM tag, including the event action, category, and label.
   * @param options.event_action - The action for the event being tagged. Defaults to 'click'.
   * @param options.event_category - The category for the event being tagged. Defaults to 'UI Interaction'.
   * @param options.event_label - The label for the event being tagged. If not provided, a default label is generated based on the current location.
   */
  public tagLink(options?: GTMTagOptions) {
    options.event_action = options.event_action || 'click';
    options.event_category = options.event_category || 'UI Interaction';

    if (!options.event_label) {
      options.event_label =
        options.event_label ||
        'Link without href on location: ' + this._location.path();
    }

    this.tagEvent(options);
  }
}
