import { ProgramStatus } from '../../enums/care-monitor/program-status';
import { DirectionOptions } from '../../enums/common/direction-options';
import { PaginationOptions } from '../common/pagination-options';
import { ProgramGuest } from './program-guest';
import { ProgramLog } from './program-log';
import { ProgramMetadata } from './program-metadata';
import { ProgramProject } from './program-project';
import { ProgramResults } from './program-results';
import { ProgramStep } from './program-step';

export class Program {
  public createdAt: Date;
  public updatedAt: Date;
  public visibleIn: Date;
  public indexCurrentStep: number;
  public guest: ProgramGuest;
  public metadata: ProgramMetadata;
  public project: ProgramProject;
  public responders: number[];
  public statusEnum: ProgramStatus;
  public statusLabel?: string;
  public steps?: ProgramStep[];
  public token: string;
  public log?: ProgramLog[];
  public results?: ProgramResults[];
}

export class ProgramList {
  public programs: Program[] = [];
  public numberOfPages: number;
  public total: number;
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 10,
    page: 0,
  };
}
