import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  faArrowDown,
  faCaretDown,
  faChevronCircleDown,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProfileCountry } from '../../../common/models/profile/profile-country';
import { AutocompleteItem } from '../../../common/models/common/autocomplete/autocomplete-item';
import { AutocompleteComponent } from '../../common/autocomplete/autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { ApiProfileV2Service } from '../../../services/api/api-profile-v2.service';
import { ProfileService, ToastService } from '@meplis/services';

@Component({
  selector: 'mec-program-text',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AutocompleteComponent,
    TranslateModule,
  ],
  templateUrl: './program-text.component.html',
  styleUrls: ['./program-text.component.scss'],
})
export class ProgramTextComponent implements OnInit, OnDestroy {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<{
    programLog: ProgramLog;
    valid: boolean;
  }>();

  public formControl: FormControl;
  public subscription: Subscription;
  public subscriptionPhone: Subscription;
  public arrowDownIcon = faCaretDown;
  public formGroup: FormGroup;

  public countries: ProfileCountry[];
  public currentMobileCountry: ProfileCountry;
  public listCoutriesAutocomplete: AutocompleteItem[];
  public selectMobileCoutryMode: boolean;

  public textTypes: { [key: string]: string } = {
    CPF: 'number',
    SIMPLE: 'text',
    EMAIL: 'email',
    PHONE: 'number',
  };
  public type: string;

  constructor(
    private _apiProfileV2Service: ApiProfileV2Service,
    private _profileService: ProfileService,
    private _toastService: ToastService,
    private _formBuilder: FormBuilder
  ) {}

  public async ngOnInit(): Promise<void> {
    this.formGroup = this._formBuilder.group({
      mobileCountry: '',
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionPhone?.unsubscribe();
  }

  public ngOnChanges(): void {
    if (this.programNode) {
      this.formControl = new FormControl();
      this.formControl.setValue(this.programNode?.log?.results[0]?.value);

      this._handleValidation();

      this.subscription = this.formControl.valueChanges.subscribe((result) => {
        if (this.programNode.subType === 'PHONE') {
          result = this.currentMobileCountry.phoneCode + result;
        }
        this._changeInput(result, this.formControl.valid);
      });
    }
  }

  public getLowerIsoCode(value: string): string {
    return value.toLowerCase();
  }

  public setMobileCoutryMode(selectMobileCoutryMode: boolean): void {
    this.selectMobileCoutryMode = selectMobileCoutryMode;
  }

  public setCountryFlagCode(item: AutocompleteItem): void {
    this.currentMobileCountry = this.countries.find(
      (country) => country.isoCode === item.id
    );
    this.formGroup.controls['mobileCountry'].setValue(
      this.currentMobileCountry.phoneCode
    );
    this.selectMobileCoutryMode = false;
  }

  private async _getCountries(): Promise<void> {
    try {
      this.countries = await this._apiProfileV2Service.getCountries();

      const profileCountryCode = await (
        await this._profileService.getProfile()
      ).country;

      this.currentMobileCountry = this.countries.find(
        (country) => country.isoCode === profileCountryCode
      );

      this.listCoutriesAutocomplete = this.countries.map((country) => ({
        id: country.isoCode,
        title: country.label,
      }));
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private async _handleValidation(): Promise<void> {
    this.type = this.textTypes[this.programNode.subType];

    let validators: ValidatorFn[] = [];
    validators.push(Validators.required);

    if (this.programNode.subType === 'EMAIL') {
      validators.push(Validators.email);
    } else if (this.programNode.subType === 'CPF') {
      validators.push(this._isValidCpf());
    } else if (this.programNode.subType === 'PHONE') {
      await this._getCountries();

      this.subscriptionPhone = this.formGroup.controls[
        'mobileCountry'
      ].valueChanges.subscribe((result) => {
        const phone =
          this.currentMobileCountry.phoneCode + this.formControl.value;
        this._changeInput(phone, this.formControl.valid);
      });
    }

    this.formControl.setValidators(validators);
  }

  private _isValidCpf(): ValidatorFn {
    return (control: AbstractControl): Validators => {
      const cpf = control.value;
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return { cpfNotValid: true };
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  private _changeInput(value: string, valid: boolean): void {
    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.variable.name, value: value }],
    };

    this.onSelectItemChange.emit({
      programLog: log,
      valid,
    });
  }
}
